import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const description = "Effective Learning Systems was founded by Bob Griswold, who for over 40 years has been helping people learn to use the power of their own minds to make profound improvements in their lives! His unique approach to guided imagery, positive affirmations, relaxation, self-hypnosis and more make his programs uniquely effective.";

export default () => (
  <Layout>
    <SEO title="Bob Griswold: Founder &amp; CEO of The WiseGuide App" description={description} />

    <div className="section">
      <div className="container">
        <div>
          <div className="justify-content-center text-center"><img src="/images/5e5bfa7cc9fe847d779d5778_Bob%20Grizwald%20photo.png" alt="" className="avatar avatar-large team-member-avatar" />
            <h1 className="team-member-heading">Bob Griswold</h1>
            <div className="subheading">Founder &amp; CEO</div>
            <div className="content-width-small team-member-bio">
              <div>{description}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

// TODO show bob's posts